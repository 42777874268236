import { cn } from "@/lib/utils";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import * as React from "react";

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      "cvc-peer cvc-inline-flex cvc-h-6 cvc-w-11 cvc-shrink-0 cvc-cursor-pointer cvc-items-center cvc-rounded-full cvc-border-2 cvc-border-transparent cvc-transition-colors focus-visible:cvc-outline-none focus-visible:cvc-ring-2 focus-visible:cvc-ring-neutral-950 focus-visible:cvc-ring-offset-2 focus-visible:cvc-ring-offset-white disabled:cvc-cursor-not-allowed disabled:cvc-opacity-50 data-[state=checked]:cvc-bg-indigo-500 data-[state=unchecked]:cvc-bg-indigo-200 dark:focus-visible:cvc-ring-neutral-300 dark:focus-visible:cvc-ring-offset-neutral-950 dark:data-[state=checked]:cvc-bg-indigo-50 dark:data-[state=unchecked]:cvc-bg-indigo-800",
      className,
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        "cvc-pointer-events-none cvc-block cvc-h-5 cvc-w-5 cvc-rounded-full cvc-bg-white cvc-shadow-lg cvc-ring-0 cvc-transition-transform data-[state=checked]:cvc-translate-x-5 data-[state=unchecked]:cvc-translate-x-0 dark:cvc-bg-indigo-950",
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
