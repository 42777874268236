"use client";

import { cn } from "@/lib/utils";
import * as SelectPrimitive from "@radix-ui/react-select";
import { Check, ChevronDown, ChevronUp } from "lucide-react";
import * as React from "react";

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(
      "cvc-flex cvc-h-10 cvc-w-full cvc-items-center cvc-justify-between cvc-rounded-md cvc-border cvc-border-neutral-200 cvc-bg-white cvc-px-3 cvc-py-2 cvc-text-sm cvc-ring-offset-white placeholder:cvc-text-neutral-500 focus:cvc-outline-none focus:cvc-ring-2 focus:cvc-ring-neutral-950 focus:cvc-ring-offset-2 disabled:cvc-cursor-not-allowed disabled:cvc-opacity-50 dark:cvc-border-neutral-800 dark:cvc-bg-neutral-950 dark:cvc-ring-offset-neutral-950 dark:placeholder:cvc-text-neutral-400 dark:focus:cvc-ring-neutral-300 [&>span]:cvc-line-clamp-1",
      className,
    )}
    {...props}
  >
    {children}
    <SelectPrimitive.Icon asChild>
      <ChevronDown className="cvc-h-4 cvc-w-4 cvc-opacity-50" />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    ref={ref}
    className={cn(
      "cvc-flex cvc-cursor-default cvc-items-center cvc-justify-center cvc-py-1",
      className,
    )}
    {...props}
  >
    <ChevronUp className="cvc-h-4 cvc-w-4" />
  </SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    ref={ref}
    className={cn(
      "cvc-flex cvc-cursor-default cvc-items-center cvc-justify-center cvc-py-1",
      className,
    )}
    {...props}
  >
    <ChevronDown className="cvc-h-4 cvc-w-4" />
  </SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName =
  SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = "popper", ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        "cvc-relative cvc-z-50 cvc-max-h-96 cvc-min-w-[8rem] cvc-overflow-hidden cvc-rounded-md cvc-border cvc-border-neutral-200 cvc-bg-white cvc-text-neutral-950 cvc-shadow-md data-[state=open]:cvc-animate-in data-[state=closed]:cvc-animate-out data-[state=closed]:cvc-fade-out-0 data-[state=open]:cvc-fade-in-0 data-[state=closed]:cvc-zoom-out-95 data-[state=open]:cvc-zoom-in-95 data-[side=bottom]:cvc-slide-in-from-top-2 data-[side=left]:cvc-slide-in-from-right-2 data-[side=right]:cvc-slide-in-from-left-2 data-[side=top]:cvc-slide-in-from-bottom-2 dark:cvc-border-neutral-800 dark:cvc-bg-neutral-950 dark:cvc-text-neutral-50",
        position === "popper" &&
          "data-[side=bottom]:cvc-translate-y-1 data-[side=left]:cvc--translate-x-1 data-[side=right]:cvc-translate-x-1 data-[side=top]:cvc--translate-y-1",
        className,
      )}
      position={position}
      {...props}
    >
      <SelectScrollUpButton />
      <SelectPrimitive.Viewport
        className={cn(
          "cvc-p-1",
          position === "popper" &&
            "cvc-h-[var(--radix-select-trigger-height)] cvc-w-full cvc-min-w-[var(--radix-select-trigger-width)]",
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
      <SelectScrollDownButton />
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn(
      "cvc-py-1.5 cvc-pl-8 cvc-pr-2 cvc-text-sm cvc-font-semibold",
      className,
    )}
    {...props}
  />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      "cvc-relative cvc-flex cvc-w-full cvc-cursor-default cvc-select-none cvc-items-center cvc-rounded-sm cvc-py-1.5 cvc-pl-8 cvc-pr-2 cvc-text-sm cvc-outline-none focus:cvc-bg-neutral-100 focus:cvc-text-neutral-900 data-[disabled]:cvc-pointer-events-none data-[disabled]:cvc-opacity-50 dark:focus:cvc-bg-neutral-800 dark:focus:cvc-text-neutral-50",
      className,
    )}
    {...props}
  >
    <span className="cvc-absolute cvc-left-2 cvc-flex cvc-h-3.5 cvc-w-3.5 cvc-items-center cvc-justify-center">
      <SelectPrimitive.ItemIndicator>
        <Check className="cvc-h-4 cvc-w-4" />
      </SelectPrimitive.ItemIndicator>
    </span>

    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn(
      "cvc--mx-1 cvc-my-1 cvc-h-px cvc-bg-neutral-100 dark:cvc-bg-neutral-800",
      className,
    )}
    {...props}
  />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
  SelectScrollUpButton,
  SelectScrollDownButton,
};
