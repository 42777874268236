import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

const badgeVariants = cva(
  "cvc-inline-flex cvc-items-center cvc-rounded-full cvc-border cvc-border-indigo-200 cvc-px-2.5 cvc-py-0.5 cvc-text-xs cvc-font-semibold cvc-transition-colors focus:cvc-outline-none focus:cvc-ring-2 focus:cvc-ring-indigo-950 focus:cvc-ring-offset-2 dark:cvc-border-indigo-800 dark:focus:cvc-ring-indigo-300",
  {
    variants: {
      variant: {
        default:
          "cvc-border-transparent cvc-bg-indigo-500 cvc-text-indigo-50 hover:cvc-bg-indigo-600 dark:cvc-bg-indigo-50 dark:cvc-text-indigo-950 dark:hover:cvc-bg-indigo-100",
        secondary:
          "cvc-border-transparent cvc-bg-indigo-100 cvc-text-indigo-900 hover:cvc-bg-indigo-100/80 dark:cvc-bg-indigo-800 dark:cvc-text-indigo-50 dark:hover:cvc-bg-indigo-800/80",
        destructive:
          "cvc-border-transparent cvc-bg-red-500 cvc-text-indigo-50 hover:cvc-bg-red-500/80 dark:cvc-bg-red-900 dark:cvc-text-indigo-50 dark:hover:cvc-bg-red-900/80",
        outline: "cvc-text-indigo-950 dark:cvc-text-indigo-50",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
