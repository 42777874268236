import { cn } from "@/lib/utils";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import * as React from "react";

const Tabs = TabsPrimitive.Root;

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      "cvc-inline-flex cvc-h-10 cvc-items-center cvc-justify-center cvc-rounded-md cvc-bg-neutral-100 cvc-p-1 cvc-text-neutral-500 dark:cvc-bg-neutral-800 dark:cvc-text-neutral-400",
      className,
    )}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      "cvc-inline-flex cvc-items-center cvc-justify-center cvc-whitespace-nowrap cvc-rounded-sm cvc-px-3 cvc-py-1.5 cvc-text-sm cvc-font-medium cvc-ring-offset-white cvc-transition-all focus-visible:cvc-outline-none focus-visible:cvc-ring-2 focus-visible:cvc-ring-neutral-950 focus-visible:cvc-ring-offset-2 disabled:cvc-pointer-events-none disabled:cvc-opacity-50 data-[state=active]:cvc-bg-white data-[state=active]:cvc-text-neutral-950 data-[state=active]:cvc-shadow-sm dark:cvc-ring-offset-neutral-950 dark:focus-visible:cvc-ring-neutral-300 dark:data-[state=active]:cvc-bg-neutral-950 dark:data-[state=active]:cvc-text-neutral-50",
      className,
    )}
    {...props}
  />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "cvc-mt-2 cvc-ring-offset-white focus-visible:cvc-outline-none focus-visible:cvc-ring-2 focus-visible:cvc-ring-neutral-950 focus-visible:cvc-ring-offset-2 dark:cvc-ring-offset-neutral-950 dark:focus-visible:cvc-ring-neutral-300",
      className,
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
