import { Button, buttonVariants } from "@/components/Button";
import { cn } from "@/lib/utils";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import * as React from "react";

type IconButtonProps = React.ComponentProps<typeof Button>;

const iconButtonVariants = cva("p-0", {
  variants: {
    size: {
      default: "cvc-h-10 cvc-w-10",
      sm: "cvc-h-9 cvc-w-9",
      xs: "cvc-h-7 cvc-w-7",
      lg: "cvc-h-11 cvc-w-11",
    },
  },
  defaultVariants: {
    size: "default",
  },
});

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          iconButtonVariants({ size }),
        )}
        ref={ref}
        {...props}
      />
    );
  },
);

export { IconButton, iconButtonVariants };
