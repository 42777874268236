import { cn } from "@/lib/utils";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";
import * as React from "react";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "cvc-peer cvc-h-4 cvc-w-4 cvc-shrink-0 cvc-rounded-sm cvc-border cvc-border-neutral-900 cvc-ring-offset-white focus-visible:cvc-outline-none focus-visible:cvc-ring-2 focus-visible:cvc-ring-neutral-950 focus-visible:cvc-ring-offset-2 disabled:cvc-cursor-not-allowed disabled:cvc-opacity-50 data-[state=checked]:cvc-border-indigo-500 data-[state=checked]:cvc-bg-indigo-500 data-[state=checked]:cvc-text-indigo-50 dark:cvc-border-neutral-800 dark:cvc-ring-offset-neutral-950 dark:focus-visible:cvc-ring-neutral-300",
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn(
        "cvc-flex cvc-items-center cvc-justify-center cvc-text-current",
      )}
    >
      <Check className="cvc-h-4 cvc-w-4" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
