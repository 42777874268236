import { cn } from "@/lib/utils";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

const buttonVariants = cva(
  "cvc-inline-flex cvc-items-center cvc-justify-center cvc-whitespace-nowrap cvc-rounded-full cvc-text-sm cvc-font-medium cvc-ring-offset-white cvc-transition-colors focus-visible:cvc-outline-none focus-visible:cvc-ring-2 focus-visible:cvc-ring-indigo-500 focus-visible:cvc-ring-offset-2 disabled:cvc-pointer-events-none disabled:cvc-opacity-50",
  {
    variants: {
      variant: {
        default: "cvc-bg-indigo-500 cvc-text-indigo-50 hover:cvc-bg-indigo-600",
        destructive:
          "cvc-bg-red-500 cvc-text-indigo-50 hover:cvc-bg-red-500/90 dark:cvc-bg-red-900 dark:cvc-text-indigo-50 dark:hover:cvc-bg-red-900/90",
        outline:
          "cvc-border cvc-border-indigo-200 cvc-bg-white hover:cvc-bg-indigo-100 hover:cvc-text-indigo-900 dark:cvc-border-indigo-800 dark:cvc-bg-indigo-950 dark:hover:cvc-bg-indigo-800 dark:hover:cvc-text-indigo-50",
        secondary:
          "cvc-bg-indigo-100 cvc-text-indigo-500 hover:cvc-bg-indigo-100/80 dark:cvc-bg-indigo-800 dark:cvc-text-indigo-50 dark:hover:cvc-bg-indigo-800/80",
        ghost:
          "hover:cvc-bg-indigo-100 hover:cvc-text-indigo-500 dark:hover:cvc-bg-indigo-800 dark:hover:cvc-text-indigo-50",
        link: "cvc-text-indigo-500 cvc-underline-offset-4 hover:cvc-underline dark:cvc-text-indigo-50",
      },
      size: {
        default: "cvc-h-10 cvc-px-4 cvc-py-2",
        sm: "cvc-h-9 cvc-px-3",
        xs: "cvc-h-7 cvc-px-2 cvc-text-xs",
        lg: "cvc-h-11 cvc-px-8",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean;
  };

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
