import { cn } from "@/lib/utils";
import * as SheetPrimitive from "@radix-ui/react-dialog";
import { cva, type VariantProps } from "class-variance-authority";
import { X } from "lucide-react";
import * as React from "react";

const Sheet = SheetPrimitive.Root;

const SheetTrigger = SheetPrimitive.Trigger;

const SheetClose = SheetPrimitive.Close;

const SheetPortal = SheetPrimitive.Portal;

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={cn(
      "cvc-fixed cvc-inset-0 cvc-z-50 cvc-bg-black/80 data-[state=open]:cvc-animate-in data-[state=closed]:cvc-animate-out data-[state=closed]:cvc-fade-out-0 data-[state=open]:cvc-fade-in-0",
      className,
    )}
    {...props}
    ref={ref}
  />
));
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;

const sheetVariants = cva(
  "cvc-fixed cvc-z-50 cvc-gap-4 cvc-bg-white cvc-p-6 cvc-shadow-lg cvc-transition cvc-ease-in-out data-[state=closed]:cvc-duration-300 data-[state=open]:cvc-duration-500 data-[state=open]:cvc-animate-in data-[state=closed]:cvc-animate-out dark:cvc-bg-neutral-950",
  {
    variants: {
      side: {
        top: "cvc-inset-x-0 cvc-top-0 cvc-border-b data-[state=closed]:cvc-slide-out-to-top data-[state=open]:cvc-slide-in-from-top",
        bottom:
          "cvc-inset-x-0 cvc-bottom-0 cvc-border-t data-[state=closed]:cvc-slide-out-to-bottom data-[state=open]:cvc-slide-in-from-bottom",
        left: "cvc-inset-y-0 cvc-left-0 cvc-h-full cvc-w-3/4 cvc-border-r data-[state=closed]:cvc-slide-out-to-left data-[state=open]:cvc-slide-in-from-left sm:cvc-max-w-sm",
        right:
          "cvc- cvc-inset-y-0 cvc-right-0 cvc-h-full cvc-w-3/4 cvc-border-l data-[state=closed]:cvc-slide-out-to-right data-[state=open]:cvc-slide-in-from-right sm:cvc-max-w-sm",
      },
    },
    defaultVariants: {
      side: "right",
    },
  },
);

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {}

const SheetContent = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  SheetContentProps
>(({ side = "right", className, children, ...props }, ref) => (
  <SheetPortal>
    <SheetOverlay />
    <SheetPrimitive.Content
      ref={ref}
      className={cn(sheetVariants({ side }), className)}
      {...props}
    >
      {children}
      <SheetPrimitive.Close className="cvc-absolute cvc-right-4 cvc-top-4 cvc-rounded-sm cvc-opacity-70 cvc-ring-offset-white cvc-transition-opacity hover:cvc-opacity-100 focus:cvc-outline-none focus:cvc-ring-2 focus:cvc-ring-neutral-950 focus:cvc-ring-offset-2 disabled:cvc-pointer-events-none data-[state=open]:cvc-bg-neutral-100 dark:cvc-ring-offset-neutral-950 dark:focus:cvc-ring-neutral-300 dark:data-[state=open]:cvc-bg-neutral-800">
        <X className="cvc-h-4 cvc-w-4" />
        <span className="cvc-sr-only">Close</span>
      </SheetPrimitive.Close>
    </SheetPrimitive.Content>
  </SheetPortal>
));
SheetContent.displayName = SheetPrimitive.Content.displayName;

const SheetHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "cvc-flex cvc-flex-col cvc-space-y-2 cvc-text-center sm:cvc-text-left",
      className,
    )}
    {...props}
  />
);
SheetHeader.displayName = "SheetHeader";

const SheetFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "cvc-flex cvc-flex-col-reverse sm:cvc-flex-row sm:cvc-justify-end sm:cvc-space-x-2",
      className,
    )}
    {...props}
  />
);
SheetFooter.displayName = "SheetFooter";

const SheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={cn(
      "cvc-text-lg cvc-font-semibold cvc-text-neutral-950 dark:cvc-text-neutral-50",
      className,
    )}
    {...props}
  />
));
SheetTitle.displayName = SheetPrimitive.Title.displayName;

const SheetDescription = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Description
    ref={ref}
    className={cn(
      "cvc-text-sm cvc-text-neutral-500 dark:cvc-text-neutral-400",
      className,
    )}
    {...props}
  />
));
SheetDescription.displayName = SheetPrimitive.Description.displayName;

export {
  Sheet,
  SheetPortal,
  SheetOverlay,
  SheetTrigger,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetFooter,
  SheetTitle,
  SheetDescription,
};
