import { cn } from "@/lib/utils";
import * as React from "react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  startSlot?: React.ReactNode;
  endSlot?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, startSlot, endSlot, ...props }, ref) => {
    return (
      <div className="cvc-relative">
        {startSlot ? (
          <div className="cvc-absolute cvc-left-3 cvc-top-1/2 cvc-flex -cvc-translate-y-1/2 cvc-items-center">
            {startSlot}
          </div>
        ) : null}

        <input
          type={type}
          className={cn(
            "cvc-flex cvc-h-10 cvc-w-full cvc-rounded-md cvc-border cvc-border-neutral-200 cvc-bg-white cvc-px-3 cvc-py-2 cvc-text-sm cvc-ring-offset-white file:cvc-border-0 file:cvc-bg-transparent file:cvc-text-sm file:cvc-font-medium placeholder:cvc-text-neutral-500 focus-visible:cvc-outline-none focus-visible:cvc-ring-2 focus-visible:cvc-ring-neutral-950 focus-visible:cvc-ring-offset-2 disabled:cvc-cursor-not-allowed disabled:cvc-opacity-50 dark:cvc-border-neutral-800 dark:cvc-bg-neutral-950 dark:cvc-ring-offset-neutral-950 dark:placeholder:cvc-text-neutral-400 dark:focus-visible:cvc-ring-neutral-300",
            startSlot ? "cvc-pl-10" : null,
            endSlot ? "cvc-pr-10" : null,
            className,
          )}
          ref={ref}
          {...props}
        />

        {endSlot ? (
          <div
            className={
              "cvc-absolute cvc-right-3 cvc-top-1/2 cvc-flex -cvc-translate-y-1/2 cvc-items-center"
            }
          >
            {endSlot}
          </div>
        ) : null}
      </div>
    );
  },
);
Input.displayName = "Input";

export { Input };
