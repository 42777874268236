import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

const alertVariants = cva(
  "cvc-relative cvc-w-full cvc-rounded-lg cvc-border cvc-border-neutral-200 cvc-p-4 dark:cvc-border-neutral-800 [&>svg+div]:cvc-translate-y-[-3px] [&>svg]:cvc-absolute [&>svg]:cvc-left-4 [&>svg]:cvc-top-4 [&>svg]:cvc-text-neutral-950 dark:[&>svg]:cvc-text-neutral-50 [&>svg~*]:cvc-pl-7",
  {
    variants: {
      variant: {
        default:
          "cvc-bg-white cvc-text-neutral-950 dark:cvc-bg-neutral-950 dark:cvc-text-neutral-50",
        destructive:
          "cvc-border-red-500/50 cvc-text-red-500 dark:cvc-border-red-500 dark:cvc-border-red-900/50 dark:dark:cvc-border-red-900 dark:cvc-text-red-900 [&>svg]:cvc-text-red-500 dark:[&>svg]:cvc-text-red-900",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(alertVariants({ variant }), className)}
    {...props}
  />
));
Alert.displayName = "Alert";

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn(
      "cvc-mb-1 cvc-font-medium cvc-leading-none cvc-tracking-tight",
      className,
    )}
    {...props}
  />
));
AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("cvc-text-sm [&_p]:cvc-leading-relaxed", className)}
    {...props}
  />
));
AlertDescription.displayName = "AlertDescription";

export { Alert, AlertTitle, AlertDescription };
