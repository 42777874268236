import { cn } from "@/lib/utils";
import * as React from "react";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          "cvc-flex cvc-min-h-[80px] cvc-w-full cvc-rounded-md cvc-border cvc-border-neutral-200 cvc-bg-white cvc-px-3 cvc-py-2 cvc-text-sm cvc-ring-offset-white placeholder:cvc-text-neutral-500 focus-visible:cvc-outline-none focus-visible:cvc-ring-2 focus-visible:cvc-ring-neutral-950 focus-visible:cvc-ring-offset-2 disabled:cvc-cursor-not-allowed disabled:cvc-opacity-50 dark:cvc-border-neutral-800 dark:cvc-bg-neutral-950 dark:cvc-ring-offset-neutral-950 dark:placeholder:cvc-text-neutral-400 dark:focus-visible:cvc-ring-neutral-300",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Textarea.displayName = "Textarea";

export { Textarea };
